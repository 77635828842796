import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

export default () => (
  <footer>
    <ul
      style={{
        listStyle: "none",
        paddingInlineStart: "0px"
      }}
    >
      <li>
        <FontAwesomeIcon icon={faEnvelope} />{" "}
        <a href="mailto:contact@jjk.is">contact@jjk.is</a>
      </li>
    </ul>
  </footer>
);
