import React from "react";
import PropTypes from "prop-types";

import "./water.css";
import "./anchor.css";

// import Nav from "./nav";
import Footer from "./footer";

const Layout = ({ children }) => (
  <div>
    {/* <Nav /> */}
    {children}
    <Footer />
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
